import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {navigate} from 'gatsby';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {Table, Tag} from 'antd';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import * as AppActions from '../../AppActions';

const columns = [
  {
    title: '標題',
    dataIndex: 'title',
    key: 'title',
    render: (text, record) => (
      <p className="link" onClick={() => navigate(`/article?id=${record.id}`)}>
        {text}
      </p>
    ),
  },
  {
    title: '類別',
    dataIndex: 'label',
    key: 'label',
    render: (tags) => (
      <>
        {tags &&
          tags.map((tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          ))}
      </>
    ),
  },
  {
    title: '創建時間',
    dataIndex: 'created',
    key: 'created',
    render: (data) => <p>{new Date(data).toLocaleDateString('en-ZA')}</p>,
  },
];

function News(props) {
  const [user] = useOutlet('user');
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    console.log('use effect news');
    async function fetchData() {
      let articles = await JStorage.fetchDocuments('Article_Default');
      try {
        AppActions.setLoading(true);
        setArticles(articles.results);
      } catch (ex) {
      } finally {
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <Wrapper>
      <h1 style={{textAlign: 'center', marginBottom: 20}}>公告列表</h1>
      <div className="content">
        {articles.filter((a) => a.label && a.label.indexOf('top') >= 0).length >
          0 &&
          articles
            .filter((a) => a.label && a.label.indexOf('top') >= 0)
            .map((a) => (
              <>
                <h2 style={{textAlign: 'center'}}>{a.title || '無標題'}</h2>
                <RichTextPreview content={a.content} />
              </>
            ))}
        <Table
          pagination={true}
          dataSource={articles.filter(
            (a) => !a.label || (a.label && a.label.indexOf('top') < 0),
          )}
          columns={columns}
          scroll={{x: '100%'}}
        />
        {/* {articles && (
          
        )} */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
  }

  & .link {
    cursor: pointer;
    :hover {
      color: #4d4d4d;
    }
  }
`;

export default News;
